import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import locales from '../locales'
import Img from 'gatsby-image'
import styles from './dermatology.module.scss'

const DermatologistPage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const content = data.allContentfulLandingPage.edges[0].node
  const heroImages = [
    {
      ...data.heroSmall.childImageSharp.fluid,
      media: `(max-width: 499px)`
    },
    {
      ...data.heroMedium.childImageSharp.fluid,
      media: `(max-width: 999px)`
    },
    {
      ...data.heroLarge.childImageSharp.fluid,
      media: `(min-width: 1000px)`
    }
  ]
  const ctaImages = [
    {
      ...data.ctaSmall.childImageSharp.fluid,
      media: `(max-width: 499px)`
    },
    {
      ...data.ctaMedium.childImageSharp.fluid,
      media: `(max-width: 999px)`
    },
    {
      ...data.ctaLarge.childImageSharp.fluid,
      media: `(min-width: 1000px)`
    }
  ]

  return (
    <Layout locale={locale}>
      <SEO
        title={content.title}
        description={content.description.description}
        hrefLangs={hrefLangs}
      />
      <main role="main" className={`${styles.main} ${locales[locale].default ? "" : styles.ja}`}>
        <section className={styles.hero}>
          <div className={styles.hero_message}>
            <h1 className={styles.hero_heading}>{content.heading1}</h1>
            <p className={styles.hero_catch}>{content.subtitle1}</p>
            <OutboundLink
              href={`https://itunes.apple.com/${locales[locale].countryCode}/app/apple-store/id1161117472?pt=118416038&ct=landingpage&mt=8`}
              className={styles.appstore}
            >
              <img
                src={`/images${locales[locale].default ? '/' : '/' + locale
                  }/appstore.svg`}
                alt="App Store"
              />
            </OutboundLink>
            <OutboundLink
              href={"https://play.google.com/store/apps/details?id=io.loquat&referrer=utm_source%3Dlandingpage"}
              className={styles.googleplay}
            >
              <img
                alt="Get it on Google Play"
                src={`/images${locales[locale].default ? '/' : '/' + locale
                  }/googleplay.svg`}
              />
            </OutboundLink>
          </div>
          <figure className={styles.hero_img}>
            <Img fluid={heroImages} className={styles.size} />
          </figure>
        </section>
        <div className={styles.wrapper}>
          <section className={styles.feature}>
            <h2>{content.instructionTitle}</h2>
            <article className={styles.snap}>
              <h3>{content.instruction1}</h3>
              <figure className={styles.snap_figure}>
                <Img fluid={data.snap.childImageSharp.fluid} />
              </figure>
              <p>{content.instructiondesc1}</p>
            </article>
            <article className={styles.describe}>
              <h3>{content.instruction2}</h3>
              <figure className={styles.describe_figure}>
                <Img fluid={data.describe.childImageSharp.fluid} />
              </figure>
              <p>{content.instructiondesc2}</p>
            </article>
            <article className={styles.result}>
              <h3>{content.instruction3}</h3>
              <figure className={styles.result_figure}>
                <Img fluid={data.answer.childImageSharp.fluid} />
              </figure>
              <p>{content.instructiondesc3}</p>
            </article>
          </section>
        </div>
        <section className={styles.cta}>
          <div className={styles.cta_message}>
            <h2 className={styles.cta_heading}>{content.heading2}</h2>
            <p className={styles.cta_catch}>{content.subtitle2}</p>
            <OutboundLink
              href={`https://itunes.apple.com/${locales[locale].countryCode}/app/apple-store/id1161117472?pt=118416038&ct=landingpage&mt=8`}
              className={styles.appstore}
            >
              <img
                src={`/images${locales[locale].default ? '/' : '/' + locale
                  }/appstore.svg`}
                alt="App Store"
              />
            </OutboundLink>
            <OutboundLink
              href={"https://play.google.com/store/apps/details?id=io.loquat&referrer=utm_source%3Dlandingpage"}
              className={styles.googleplay}
            >
              <img
                alt="Get it on Google Play"
                src={`/images${locales[locale].default ? '/' : '/' + locale
                  }/googleplay.svg`}
              />
            </OutboundLink>
          </div>
          <figure className={styles.cta_img}>
            <Img fluid={ctaImages} className={styles.size} />
          </figure>
        </section>
      </main>
    </Layout>
  )
}

export default DermatologistPage

export const query = graphql`
  query landingPage($localeLanguage: String!) {
    allContentfulLandingPage(filter: { node_locale: { eq: $localeLanguage } }) {
      edges {
        node {
          title
          description {
            description
          }
          heading1
          subtitle1
          heroimage
          heading2
          subtitle2
          heading3
          subtitle3
          recommendationTitle
          recommend1
          recommenddesc1
          recommend2
          recommenddesc2
          recommend3
          recommenddesc3
          recommend4
          recommenddesc4
          recommend5
          recommenddesc5
          instructionTitle
          instruction1
          instructiondesc1
          instruction2
          instructiondesc2
          instruction3
          instructiondesc3
          videoUrl
          charge {
            priceTitle
            priceHeading
          }
          example {
            title
            permission
            consultation
            ageLabel
            age
            genderLabel
            gender
            itchingLabel
            itching
            painLabel
            pain
            sinceLabel
            since
            photoLabel
            distribution
            medicalTreatmentLabel
            medicalTreatment
            takingMedicationLabel
            takingMedication
            commentLabel
            comment
            answerLabel
            answer {
              childMarkdownRemark {
                html
              }
            }
          }
          news
          newsList
          copyTitle
          copy
          appurl
          appstyle
          appUrlCampaign
          appUrlCampaign2
          androidAppUrlCampaign
          androidAppUrlCampaign2
          androidImageUrl
        }
      }
    }
    allContentfulNews(
      sort: { fields: [publishedAt], order: DESC }
      filter: { node_locale: { eq: $localeLanguage } }
      limit: 5
    ) {
      edges {
        node {
          title
          newsId
          publishedAt
        }
      }
    }
    heroSmall: file(relativePath: { eq: "hero.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 375, maxHeight: 520) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMedium: file(relativePath: { eq: "hero.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, maxHeight: 510) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroLarge: file(relativePath: { eq: "hero.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1070) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    snap: file(relativePath: { eq: "dermatologist/snap.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    describe: file(relativePath: { eq: "dermatologist/describe.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    answer: file(relativePath: { eq: "dermatologist/answer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaSmall: file(relativePath: { eq: "cta.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 375, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaMedium: file(relativePath: { eq: "cta.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaLarge: file(relativePath: { eq: "cta.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1070) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
